






































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import { RootComponent, SidebarAction } from '@/App.vue';

export default Vue.extend({
  name: 'Authorize-Page',
  components: { Loading },
  data: () => {
    return {
      errored: false,
      error: '',
      errorDictionary: {
        // Email not verified
        '-1315173777': {
          code: '-1315173777',
          reason: 'Email not verified',
          suggestion:
            'Verify email address; check your spam folders. Alternatively request a new verification email',
        },
        // Verify email error (Hashed)
        '1989130775': {
          code: '1989130775',
          reason: 'Account blocked',
          suggestion: 'Contact Support',
        },
        // Account blocked error (Hashed)
        '-1721521720': {
          code: '-1721521720',
          reason: 'User did not authroise request',
          suggestion: 'Logout -> Sign In -> Accept',
        }, // User did not allow access from app to auth0
      } as ErrorDictionary,
    };
  },
  computed: {
    title (): string {
      if (this.errored) {
        return 'Verification Failed';
      } else {
        return 'Verifying';
      }
    },
    errorObject (): ErrorLookup {
      // If we have code and an relating object
      if (this.error && this.errorDictionary[this.error]) {
        return this.errorDictionary[this.error];
      }
      return { reason: 'Unknown', suggestion: 'Contact Support', code: '0' };
    },
    isVerifyEmailError (): boolean {
      if (this.errorObject.code === '-1315173777') {
        return true;
      }
      return false;
    },
  },
  created () {
    if (window.location.search.includes('error')) {
      // Set error state
      this.errored = true;
      // Check if error description exists
      if (window.location.search.includes('error_description')) {
        const regexErrorDescription: RegExp = new RegExp(/(error_description=)(.*)&/gm);
        const regexReplaceSpaces: RegExp = new RegExp(/(%20)/gm);
        const regexMatches: RegExpExecArray | null = regexErrorDescription.exec(window.location.search);
        if (regexMatches && regexMatches.length === 3) {
          // hash the error to get code
          this.error = this.hashError(regexMatches[2]).toString();
        }
      }
    }
  },
  methods: {
    hashError (error: string) {
      const regexReplaceSpaces: RegExp = new RegExp(/(%20)/gm);
      error = error.toLowerCase().replace(regexReplaceSpaces, '');
      let hash: number = 0;
      if (error.length === 0) {
        return hash;
      }
      for (let i: number = 0; i < error.length; i++) {
        let char: number = error.charCodeAt(i);
        /* tslint:disable-next-line */
        hash = (hash << 5) - hash + char;
        /* tslint:disable-next-line */
        hash = hash & hash; // Convert to 32bit integer
      }
      console.log(hash);
      return hash;
    },
    requestVerificationEmail () {
      const payload: SidebarAction = {
        state: 'Open',
        component: 'VerifyEmailRequest',
      };
      (this.$root.$children[0] as RootComponent)['sidebarAction'](payload);
    },
    logout () {
      this.$auth0.logout(this);
    },
  },
});

interface ErrorDictionary {
  [x: string]: ErrorLookup;
}

interface ErrorLookup {
  reason: string;
  suggestion: string;
  code: string;
}
